import React from 'react';
import styled from 'styled-components';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {  AccessTimeFilled, WhatsApp } from '@mui/icons-material';
import { YouTube } from '@mui/icons-material';
import { Bio } from '../../data/constants';

const StyledSidebar = styled.div`
  position: fixed;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  z-index: 999999999999999999999999999999;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  @media (max-width: 960px) {
    top: 45%;
    
  }
`;

const ChildButtonsContainer = styled.div`
  background: linear-gradient(225deg, hsla(271, 100%, 50%, 1) 0%, hsla(294, 100%, 40%, 1) 100%);
  // background: transparent;
  // box-shadow: 20px 20px 60px #1f2634, -20px -20px 60px #1f2634;
  position: relative;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  margin: 0 -1rem;
`;

const SocialMediaIcon = styled.a`
  display: inline-block;
  margin: 0 1rem;
  font-size: 2rem;
  color: ${({ theme }) => theme.text_primary};
  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

const Sidebar = () => {
  return (
    <StyledSidebar>
      <ChildButtonsContainer>
        {/* <SocialMediaIcon href={Bio.MicrosoftLearn} target="_blank" rel="noopener noreferrer">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M2 3h9v9H2V3m9 19H2v-9h9v9M21 3v9h-9V3h9m0 19h-9v-9h9v9Z" /></svg>
        </SocialMediaIcon> */}
        <SocialMediaIcon href={Bio.linkedin} target="_blank" rel="noopener noreferrer">
          <LinkedInIcon />
        </SocialMediaIcon>
        <SocialMediaIcon href={Bio.twitter} target="_blank" rel="noopener noreferrer">
          <TwitterIcon />
        </SocialMediaIcon>
        <SocialMediaIcon href={Bio.youtube} target="_blank" rel="noopener noreferrer">
          <YouTube />
        </SocialMediaIcon>
        <SocialMediaIcon href={Bio.cal} target="_blank" rel="noopener noreferrer">
          < AccessTimeFilled />
        </SocialMediaIcon>
        <SocialMediaIcon href={Bio.whatsApp} target="_blank" rel="noopener noreferrer">
          < WhatsApp />
        </SocialMediaIcon>

      </ChildButtonsContainer>
    </StyledSidebar>
  );
};

export default Sidebar;
