import React from "react";
import styled from "styled-components";
import CertificationCard from "../Cards/CertificationCard";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 20px; /* Adjusted padding for better spacing */
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto; /* Center the content */
  gap: 20px; /* Increased gap for better spacing */
`;

const Title = styled.div`
  font-size: 42px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.text_primary};
`;

const CertificationSection = styled.div`
  display: flex;
  gap: 20px;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none; /* Remove scrollbar */
  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar for WebKit browsers */
  }
  @media (min-width: 1200px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    overflow-x: scroll; /* Update to scroll for mobile screens */
    max-width: 100%; /* Set a maximum width */
  }
`;

const CertificationCardWrapper = styled.div`
  flex: 0 0 auto;
  width: 30vw; /* Adjusted width for better display on larger screens */
  height: auto;
  @media (max-width: 1200px) {
    width: 45vw; /* Adjusted width for larger screens */
  }
  @media (max-width: 768px) {
    width: 80vw; /* 80% of the viewport width on smaller screens */
  }
`;

const Certifications = ({ certifications }) => {
  return (
    <Container id="certifications">
      <Wrapper>
        <Title>Certifications & Achievements</Title>
        <CertificationSection>
          {certifications.map((certification, index) => (
            <CertificationCardWrapper key={index}>
              <CertificationCard certification={certification} />
            </CertificationCardWrapper>
          ))}
        </CertificationSection>
      </Wrapper>
    </Container>
  );
};

export default Certifications;
