import React, { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { darkTheme, lightTheme } from "./utils/Themes.js";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import HeroSection from "./components/HeroSection";
import Skills from "./components/Skills";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import { certifications } from "./data/constants.js";
import Certifications from "./components/Certifications/Certification.js";
import Education from "./components/Education";
import ProjectDetails from "./components/ProjectDetails";
import styled from "styled-components";
import Sidebar from "./components/Cards/SideBarSection.jsx";
import Loading from "./loading/Loader.jsx";
import Experience from "./components/Experience";
import SmallScreenComponent from "./components/SmallScreen";
import Navbar from "./components/Navbar";

const Body = styled.div`
  background-color: ${({ theme }) => theme.bg};
  width: 100%;
  overflow-x: hidden;
`;

const Wrapper = styled.div`
  background: linear-gradient(
      38.73deg,
      rgba(204, 0, 187, 0.15) 0%,
      rgba(201, 32, 184, 0) 50%
    ),
    linear-gradient(
      141.27deg,
      rgba(0, 70, 209, 0) 50%,
      rgba(0, 70, 209, 0.15) 100%
    );
  width: 100%;
  z-index: -1000000000000000000000000000000;
  clip-path: polygon(0 0, 99% 0, 100% 99.5%, 0 100%, 0% 100%);
  display: ${({ loaded }) => (loaded ? "block" : "none")};
`;

function App() {
  // eslint-disable-next-line
  const [darkMode, setDarkMode] = useState(true);
  const [openModal, setOpenModal] = useState({ state: false, project: null });
  const [loaded, setLoaded] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const calculateLoadTime = () => {
      const perfTiming = performance.timing;
      const loadTime = perfTiming.loadEventEnd - perfTiming.navigationStart;
      const timeoutDuration = loadTime > 0 ? loadTime : 5000;

      const timer = setTimeout(() => {
        setLoaded(true);
      }, timeoutDuration);

      return () => clearTimeout(timer);
    };

    if (performance && performance.timing) {
      calculateLoadTime();
    } else {
      const timer = setTimeout(() => {
        setLoaded(true);
      }, 100);

      return () => clearTimeout(timer);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

      if ((screenWidth < 360 && screenHeight < 748) || (screenHeight < 600) ) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Router>
        {!loaded && <Loading />}
        {loaded && (
          <Body>
            {isSmallScreen ? (
              <SmallScreenComponent />
            ) : (
              <>
                <Navbar />
                <Sidebar />
                <HeroSection />
                <Skills />
                <Experience />
                <Certifications certifications={certifications} />

                <Wrapper loaded={loaded}>
                  <Projects openModal={openModal} setOpenModal={setOpenModal} />
                </Wrapper>
                <Education />
                <Contact />
                <Footer />
                {openModal.state && (
                  <ProjectDetails
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                  />
                )}
              </>
            )}
          </Body>
        )}
      </Router>
    </ThemeProvider>
  );
}

export default App;
