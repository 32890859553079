import React from "react";
import {
  Nav,
  NavLink,
  NavbarContainer,
  Span,
  NavLogo,
  NavItems,
  GitHubButton,
  ButtonContainer,
  MobileIcon,
  MobileMenu,
  MobileLink
} from "./NavbarStyledComponent";
import { DiCssdeck } from "react-icons/di";
import { FaBars } from "react-icons/fa";
import { Bio } from "../../data/constants";
import { useTheme } from "styled-components";

const Navbar = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const theme = useTheme();

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const redirectToGitHub = () => {
    window.open(Bio.github, "_blank");
  };

  return (
    <Nav>
      <NavbarContainer>
        <NavLogo to="/">
          <a
            href="/"
            style={{
              display: "flex",
              alignItems: "center",
              color: "white",
              marginBottom: "20px", // corrected style attribute
              cursor: "pointer",
            }}
          >
            <DiCssdeck size="3rem" /> <Span>Portfolio</Span>
          </a>
        </NavLogo>
        <MobileIcon>
          <FaBars onClick={() => setIsOpen(!isOpen)} />
        </MobileIcon>
        <NavItems>
          <NavLink onClick={() => scrollToSection("about")}>About</NavLink>
          <NavLink onClick={() => scrollToSection("skills")}>Skills</NavLink>
          <NavLink onClick={() => scrollToSection("certifications")}>
            Certification
          </NavLink>
          <NavLink onClick={() => scrollToSection('experience')}>Experience</NavLink>
          <NavLink onClick={() => scrollToSection("projects")}>
            Projects
          </NavLink>
          <NavLink onClick={() => scrollToSection("education")}>
            Education
          </NavLink>
        </NavItems>
        <ButtonContainer>
          <GitHubButton onClick={redirectToGitHub}>Github</GitHubButton>
        </ButtonContainer>
        {isOpen && (
          <MobileMenu isOpen={isOpen}>
            <MobileLink
              onClick={() => {
                scrollToSection("about");
                setIsOpen(!isOpen);
              }}
            >
              About
            </MobileLink>
            <MobileLink
              onClick={() => {
                scrollToSection("skills");
                setIsOpen(!isOpen);
              }}
            >
              Skills
            </MobileLink>
            <MobileLink
              onClick={() => {
                scrollToSection("certifications");
                setIsOpen(!isOpen);
              }}
            >
              Certification
            </MobileLink>
            {/* <MobileLink onClick={() => { scrollToSection('experience'); setIsOpen(!isOpen); }}>Experience</MobileLink> */}
            <MobileLink
              onClick={() => {
                scrollToSection("projects");
                setIsOpen(!isOpen);
              }}
            >
              Projects
            </MobileLink>
            <MobileLink
              onClick={() => {
                scrollToSection("education");
                setIsOpen(!isOpen);
              }}
            >
              Education
            </MobileLink>
            <GitHubButton
              style={{
                padding: "10px 16px",
                background: `${theme.primary}`,
                color: "white",
                width: "max-content",
              }}
              onClick={Bio.github} // Directly call the function
            >
              GitHub
            </GitHubButton>
          </MobileMenu>
        )}
      </NavbarContainer>
    </Nav>
  );
};

export default Navbar;
