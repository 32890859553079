import React from "react";
import HeroBgAnimation from "../HeroBgAnimation";
import {
  HeroContainer,
  HeroBg,
  HeroLeftContainer,
  Img,
  HeroRightContainer,
  HeroInnerContainer,
  TextLoop,
  Title,
  Span,
  ResumeButton,
  SubTitle,
} from "./HeroStyle";
import HeroImg from "../../image/HeroImage6.png";
import Typewriter from "typewriter-effect";
import { Bio } from "../../data/constants";
import Sidebar from "../Cards/SideBarSection.jsx";
import CountUp from "react-countup";

// const url = "http://localhost:3000/KrupeshResume-16-3-24.pdf";
const url = "https://krupesh.dev/KrupeshResume.pdf";
const HeroSection = () => {
  const handleDownloadResume = (url) => {
    const fileName = url.split("/").pop();
    const aTage = document.createElement("a");
    aTage.href = url;
    aTage.download = fileName;
    aTage.click();
    aTage.remove();
  };

  return (
    <div id="about">
      <HeroContainer>
        <Sidebar />
        <HeroBg>
          <HeroBgAnimation />
        </HeroBg>
        <HeroInnerContainer>
          <HeroLeftContainer tainer id="Left">
            <Title>
              Hey, I am <br /> {Bio.name}
            </Title>
            <TextLoop>
              I am a
              <Span>
                <Typewriter
                  options={{
                    strings: Bio.roles,
                    autoStart: true,
                    loop: 30,
                    delay: 80,
                    wrapperClassName: "typewriter-wrapper",
                    cursorClassName: "typewriter-cursor",
                    pauseFor: 600,
                    deleteSpeed: 70,
                  }}
                />
              </Span>
            </TextLoop>
            <SubTitle>
              <CountUp start={0} end={9000} duration={3} />+ lines of code &{" "}
              Worked on <CountUp start={0} end={12} duration={3} />+ Projects
            </SubTitle>
            <ResumeButton
              onClick={() => {
                handleDownloadResume(url);
              }}
            >
              Download Resume
            </ResumeButton>
          </HeroLeftContainer>

          <HeroRightContainer id="Right">
            <Img src={HeroImg} alt="hero-image" />
          </HeroRightContainer>
        </HeroInnerContainer>
      </HeroContainer>
    </div>
  );
};

export default HeroSection;
